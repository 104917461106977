<template>
  <modal-vue
    :visible.sync="visible"
    :modal_id="'quick_view_modal'"
    :content_loaded="document_loaded"
    :size_modal="'modal-lg'"
    :auto-close-enabled="true"
  >
    <template v-slot:header>
      <span class="align-self-center mr-auto">
        {{ t("components.documents.modal_quick_view.title") }}
      </span>
    </template>

    <template v-slot:body>
      <div v-if="document_load_error" class="error_message">
        {{ t("components.documents.modal_quick_view.load_error") }}
      </div>
      <documents-quick-view-vue
        v-if="!document_load_error"
        :id="parseInt(requested_id)"
        :numero_tol="numero_tol"
        :clazz="clazz"
        :header_fields="header_fields"
        :body_fields="body_fields"
        :indice_fields="indice_fields"
        :descriptores="descriptores"
        :file_fields="file_fields"
        :metadata_fields="metadata_fields"
        :cleaned_tokens="cleanedTokens"
        :show_all="true"
        :highlight_background="false"
      />
    </template>

    <template v-slot:footer>
      <template v-if="document_loaded && !emptyDocumentText && copyButtonsVisible">
        <button class="btn btn-primary" @click="copy_selected">
          {{ t("components.documents.modal_quick_view.copy_selected") }}
        </button>
        <button class="btn btn-primary" @click="copy_all">
          {{ t("components.documents.modal_quick_view.copy_all") }}
        </button>
      </template>
      <a
        v-if="document_loaded"
        class="btn btn-primary"
        type="button"
        target="_blank"
        :href="url_for_rails_documento_show(docid, {}, article_in_requested_id)"
      >
        {{ t("components.documents.modal_quick_view.button") }}
      </a>
      <button class="btn btn-secondary" type="button" @click="hide_modal">
        {{ t("components.documents.modal_quick_view.close") }}
      </button>
    </template>
  </modal-vue>
</template>

<script>
import Vue from "vue"
import mixins from "common/mixins"
import documents_service from "common/services/documents"

export default {
  name: "modal-quick-view-vue",
  mixins: [mixins.translate, mixins.requests],
  props: {
    bus: { type: Object, default: () => new Vue() },
    copyButtonsVisible: { type: Boolean, default: false },
    searchType: { type: String, default: '' },
    cleanedTokens: { type: Array, default: () => [] }
  },
  components: {
    "modal-vue": () => import(/* webpackChunkName: "[request]" */ "components/Modal"),
    "documents-quick-view": () => import(/* webpackChunkName: "[request]" */ "components/documents/QuickView")
  },

  data() {
    return {
      document_loaded: false,
      document_load_error: false,
      visible: false,
      requested_id: "",
      docid: "",
      clazz: "",
      numero_tol: "",
      header_fields: [],
      body_fields: [],
      indice_fields: [],
      descriptores: {},
      file_fields: [],
      metadata_fields: [],
      document_type: ''
    }
  },

  created() {
    documents_service(this.bus)

    this.bus.$on("document_show", (document_id, document_type) => {
      this.show_document(document_id, document_type)
    })

    this.bus.$on("document_loaded", ({ data }) => {
      this.document_loaded = true
      this.set_data(data)
      if (this.has_article_in_requested_id) this.scroll_to_article()
    })

    this.bus.$on("document_load_error", () => {
      this.document_loaded = true
      this.document_load_error = true
    })
  },

  computed: {
    article_in_requested_id() {
      if (this.requested_id.includes("#"))
        return this.requested_id.split("#")[1]
      else return ""
    },
    has_article_in_requested_id() {
      return this.article_in_requested_id != ""
    },
    emptyDocumentText() {
      return this.document_type == "pdf_document" || this.body_fields.length < 1
    },
  },

  methods: {
    set_data(data) {
      this.docid = String(data.id)
      this.clazz = data.clazz
      this.numero_tol = data.numeroTOL
      this.header_fields = data.header_fields
      this.body_fields = data.body_fields
      this.file_fields = data.file_fields
      this.indice_fields = []
      this.metadata_fields = data.metadata_fields
      this.descriptores = data.descriptores
    },
    reset_modal() {
      this.document_loaded = false
      this.document_load_error = false
      this.docid = ""
      this.clazz = ""
      this.numero_tol = ""
      this.header_fields = []
      this.body_fields = []
      this.indice_fields = []
      this.metadata_fields = []
      this.descriptores = {}
    },
    hide_modal() {
      this.visible = false
    },
    show_modal() {
      this.visible = true
    },
    scroll_to_article() {
      Vue.nextTick(() => {
        this.scroll_when_available()
      })
    },
    scroll_when_available(elementId, retries = 10) {
      const element = document.getElementById(this.article_in_requested_id)
      const delay = 100

      if (element) {
        element.scrollIntoView()
      } else if (retries > 0) {
        setTimeout(() => {
          this.scroll_when_available(elementId, retries - 1)
        }, delay)
      }
    },
    copy_all() {
      let array_text = this.body_fields[0].value

      let document_text = ""

      array_text.forEach((element) => {
        document_text += element + "<br><br>"
      })

      this.paste_text_editor(document_text)
    },
    copy_selected() {
      let text_selected = ""

      if (document.selection) {
        text_selected = document.selection.createRange().Text
      } else {
        text_selected = document.getSelection()
      }

      if (text_selected.toString() != "") {
        text_selected = text_selected
          .toString()
          .replace(/(?:\r\n|\r|\n)/g, "<br>")
        text_selected += "<br><br><br>"

        this.paste_text_editor(text_selected)
      }
    },
    paste_text_editor(text) {
      tinymce.activeEditor.execCommand("mceInsertContent", false, text)
    },
    show_document(document_id, document_type) {
      this.requested_id = document_id
      this.reset_modal()
      this.bus.$emit("document_load_request", document_id)
      this.show_modal()

      this.document_type = document_type
    }
  }
}
</script>

<style scoped>
.error_message {
  padding: 20px;
  font-size: 12pt;
  color: red;
}
</style>
